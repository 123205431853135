* {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
}

body{
  width: 100%;
  overflow-x: hidden;
}

a{
  text-decoration: none;
  color: black;
  
}

li{
  list-style: none;
}
@keyframes animateDown{
  0%, 20%, 50%, 80%, 100%{
  transform: translateY(0);
  }

 40% {transform: translateY(5px);}
 50% {transform: translateY(3px);}
}